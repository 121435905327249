
import { PropOptions } from 'vue'
import { AUTH_MODAL_TYPES } from '~/utils/constants/auth'
import { UiTitle } from '~/components/atoms'
import {
  AuthenticationModalToggleLink,
  AuthenticationModalAuthOptions,
  AuthenticationModalDisclaimer,
} from '~/components/molecules'
import { SignInForm } from '~/components/organisms'

export default {
  name: 'LoginModal',
  components: {
    UiTitle,
    SignInForm,
    AuthenticationModalToggleLink,
    AuthenticationModalAuthOptions,
    AuthenticationModalDisclaimer,
  },
  props: {
    direction: {
      type: String,
      default: 'ltr',
    } as PropOptions<'ltr' | 'rtl'>,
    preOpenForm: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForm: this.preOpenForm,
      authModalTypes: AUTH_MODAL_TYPES,
    }
  },
}
