
import { UiLink, UiTitle } from '@/components/atoms'
import { ForgotPasswordForm } from '~/components/organisms'

export default {
  name: 'ForgotPasswordModal',
  components: {
    ForgotPasswordForm,
    UiTitle,
    UiLink,
  },
  data() {
    return {
      isResetMailSend: false,
      email: null,
    }
  },
  computed: {
    title(): string {
      return this.isResetMailSend
        ? this.$t('authentication.forgot_password.confirmed.header')
        : this.$t('authentication.forgot_password.header')
    },
    dataTest(): string {
      return this.isResetMailSend ? 'reset-link-sent' : 'reset-your-password'
    },
  },
  methods: {
    onSubmit(email) {
      this.isResetMailSend = true
      this.email = email
    },
  },
}
