
import { mapGetters } from 'vuex'
import { clearStorageItem } from '~/services/localstorage'
import { VERIFICATION_CODE_RESEND_AT_KEY } from '~/services/constants'
import { UiTitle, UiButton, Loader } from '~/components/atoms'

export default {
  name: 'ForgotPasswordModal',
  components: {
    UiTitle,
    UiButton,
    Loader,
  },
  data() {
    return {
      isLoading: true,
      isNewCodeSent: false,
      isConfirmedAndSignedIn: false,
      showGenericError: false,
    }
  },
  computed: {
    ...mapGetters('context', ['marketplacePrefix']),
    hasNecessaryData() {
      return !!this.$route.query.id && !!this.$route.query.token
    },
    title(): Record<string, string> {
      if (this.isConfirmedAndSignedIn)
        return {
          text: this.$t(
            'authentication.email_verification.verification_succeeded_header'
          ),
          dataTest: 'email-address-verification-successful',
        }

      return {
        text: this.$t(
          'authentication.email_verification.verification_failed_header'
        ),
        dataTest: 'verification-failed',
      }
    },
    description(): Record<string, string> {
      if (this.isConfirmedAndSignedIn)
        return {
          text: this.$t(
            'authentication.email_verification.verification_succeeded_text'
          ),
          dataTest: 'verification-failed',
        }

      if (this.isNewCodeSent)
        return {
          text: this.$t('authentication.email_verification.code_expired_text'),
          dataTest: 'verification-failed-code-expired',
        }

      return {
        text: this.$t('authentication.email_verification.errors.generic'),
        dataTest: '',
      }
    },
  },
  mounted() {
    if (this.hasNecessaryData) {
      this.verifyAccount()
    } else {
      this.showGenericError = true
      this.isLoading = false
    }
  },
  methods: {
    async verifyAccount() {
      try {
        const {
          data: result,
        } = await this.$auth.accountClient.auth.confirmSignUp({
          id: this.$route.query.id,
          code: this.$route.query.token,
          locale: this.marketplacePrefix,
          client_id: process.env.AWS_USER_POOL_WEB_CLIENT_ID,
        })

        clearStorageItem({ storage: VERIFICATION_CODE_RESEND_AT_KEY })

        if (result.status === 'failed') {
          this.showGenericError = true

          return
        }

        if (result.status === 'new_code_sent') {
          this.isNewCodeSent = true

          return
        }

        if (result.status === 'confirmed') {
          this.$notifications.pushInfo({
            text: this.$t(
              'authentication.email_verification.verified_and_not_signed_in_notification'
            ),
            closeAfter: 8000,
          })
          this.$gtmEnhanced.emailVerified()
          this.$router.push({ path: this.$contextPath('/?auth=login') })

          return
        }

        if (result.status === 'confirmed_and_signed_in') {
          Object.entries(result.payload).forEach(([cookieName, value]) => {
            this.$auth.storage.setItem(cookieName, value)
          })

          await this.$auth.refreshUser()

          this.isConfirmedAndSignedIn = true
          this.$gtmEnhanced.emailVerified()
        }
      } catch (e) {
        this.$sentry.captureException(e)
        this.showGenericError = true
      } finally {
        this.isLoading = false
      }
    },
  },
}
