
import { PropOptions } from 'vue'
import {
  AuthenticationModalToggleLink,
  AuthenticationModalAuthOptions,
  AuthenticationModalDisclaimer,
  AuthenticationModalRegistrationSuccessful,
} from '@/components/molecules'
import { RegisterForm } from '@/components/organisms'
import { AUTH_MODAL_TYPES } from '@/utils/constants/auth'
import { UiTitle } from '~/components/atoms'
import referAFriend from '~/mixins/refer-a-friend'

export default {
  name: 'RegisterModal',
  components: {
    UiTitle,
    RegisterForm,
    AuthenticationModalToggleLink,
    AuthenticationModalAuthOptions,
    AuthenticationModalDisclaimer,
    AuthenticationModalRegistrationSuccessful,
  },
  mixins: [referAFriend],
  props: {
    direction: {
      type: String,
      default: 'ltr',
    } as PropOptions<'ltr' | 'rtl'>,
    unfinishedVerification: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showForm: false,
      registrationSuccessful: false,
      authModalTypes: AUTH_MODAL_TYPES,
      resendEmail: false,
    }
  },
  computed: {
    modalTitle() {
      return !this.registrationSuccessful
        ? this.$t('authentication.register.create_free_recharge_account')
        : this.$t('authentication.register_confirmation.header')
    },
  },
  methods: {
    finishedRegistration(resendEmail) {
      this.showForm = false
      this.registrationSuccessful = true
      this.resendEmail = resendEmail
    },
  },
}
