
import { mapGetters } from 'vuex'
import LoginModal from './modal-content/login-modal.vue'
import { Overlay } from '~/components/molecules'
import { AUTH_MODAL_TYPES } from '~/utils/constants/auth'
import RegisterModal from '~/components/organisms/authentication-modal/modal-content/register-modal.vue'
import AccountVerificationModal from '~/components/organisms/authentication-modal/modal-content/account-verification-modal.vue'
import ForgotPasswordModal from '~/components/organisms/authentication-modal/modal-content/forgot-password-modal.vue'
import referAFriend from '~/mixins/refer-a-friend'

export default {
  name: 'AuthenticationModal',
  components: {
    ForgotPasswordModal,
    RegisterModal,
    Overlay,
    LoginModal,
    AccountVerificationModal,
  },
  mixins: [referAFriend],
  data() {
    return {
      showLoginForm: false,
      unfinishedVerification: false,
    }
  },
  computed: {
    ...mapGetters('context', ['direction']),
    ...mapGetters('ui/notifications', ['notifications']),
    authModalTypes() {
      return AUTH_MODAL_TYPES
    },
    activeModalContent(): string {
      const queryParam = this.$route.query.auth

      if (
        AUTH_MODAL_TYPES.REGISTER === queryParam ||
        this.isRafInvitationPage
      ) {
        return AUTH_MODAL_TYPES.REGISTER
      }

      if (AUTH_MODAL_TYPES.LOGIN === queryParam) {
        return AUTH_MODAL_TYPES.LOGIN
      }

      if (AUTH_MODAL_TYPES.ACCOUNT_VERIFICATION === queryParam) {
        return AUTH_MODAL_TYPES.ACCOUNT_VERIFICATION
      }

      return AUTH_MODAL_TYPES.FORGOT_PASSWORD
    },
    isMobile() {
      return this.$mq === 'sm' || this.$mq === 'md'
    },
  },
  async mounted() {
    this.$notifications.restoreDeferredNotifications()
    await this.$store.dispatch(
      'ui/mobile-menu/setMobileMenuVisibility',
      'hidden'
    )
    this.$store.dispatch('ui/toggleOverlay', {
      name: 'authenticationModal',
      visibility: 'visible',
    })
    const bodyHTMLElement = document.querySelector('body') as HTMLBodyElement
    bodyHTMLElement.classList.add('overflow-hidden')
  },
  destroyed() {
    const bodyHTMLElement = document.querySelector('body') as HTMLBodyElement
    bodyHTMLElement.classList.remove('overflow-hidden')
  },
  methods: {
    handleOverlayClose(): void {
      this.$emit('auth-modal-close')
    },
    openForgotPasswordPrefilled(): void {
      const queryObject = this.$route.query
      this.$router.replace({
        query: {
          ...queryObject,
          auth: AUTH_MODAL_TYPES.FORGOT_PASSWORD,
          'pre-fill': true,
        },
      })
    },
    closeForgotPassword() {
      this.showLoginForm = true
      this.$router.push(this.$contextPath(`/?auth=${AUTH_MODAL_TYPES.LOGIN}`))
    },
    signUpExistingAccount() {
      this.unfinishedVerification = true
      this.$router.push(
        this.$contextPath(`/?auth=${AUTH_MODAL_TYPES.REGISTER}`)
      )
    },
  },
}
